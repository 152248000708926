























































































































































































































































































































































.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.statistics {
  margin-bottom: 10px;
  > span + span {
    margin-left: 10px;
  }
}
.className :empty:not(th):not(tbody)::before{
	content:'--';
	color:#606266;
}
/deep/ .pre-wrap .cell{
  white-space: pre-wrap;
}
